import { FirebaseAuthProvider } from "react-admin-firebase";

const config = {
  apiKey: "AIzaSyC5VmOr0NtTxvXaPVeKR7rhmgsxZnVmlpM",
  authDomain: "goodplaces-app.firebaseapp.com",
  databaseURL: "https://goodplaces-app.firebaseio.com",
  projectId: "goodplaces-app",
};

const options = {};

export const authProvider = FirebaseAuthProvider(config, options);
