import { fetchUtils } from "react-admin";
import { authProvider } from "./auth";

export const apiUrl = process.env.REACT_APP_ADMIN_URL;

export const httpClient = async (url, options = {}) => {
  const token = await authProvider.getJWTToken().then((token) => token);
  options.headers = new Headers({ Accept: "application/json" });
  options.headers.set("Authorization", `Bearer ${token}`);
  const { json } = await fetchUtils.fetchJson(url, options);
  return json;
};

const resourceToPath = {
  admins: "admins",
  users: "users",
  posts: "posts",
  featured_users: "featured-users",
  invites: "invites",
  waitlist: "waitlist",
  reports: "reports",
  feedback: "feedback",
  deleted_users: "deleted-users",
};

const idField = {
  admins: "username",
  users: "username",
  posts: "postId",
  featured_users: "username",
  invites: "phoneNumber",
  waitlist: "phoneNumber",
  reports: "id",
  feedback: "id",
  deleted_users: "username",
};

const deleteResourceKeys = {
  invites: "phoneNumbers",
};

function withId(resource, object) {
  return {
    ...object,
    id: object[idField[resource]],
  };
}

const dataProvider = {
  getList: async (resource, params) => {
    let path = resourceToPath[resource];
    let page = params.pagination.page;
    let limit = params.pagination.perPage;
    const url = `${apiUrl}/${path}?page=${page}&limit=${limit}`;
    let response = await httpClient(url);
    let data = response.data.map((item) => withId(resource, item));
    return {
      data: data,
      total: response.total,
    };
  },

  getOne: async (resource, params) => {
    // Only works for users and posts
    let path = resourceToPath[resource];
    const url = `${apiUrl}/${path}/${params.id}`;
    let response = await httpClient(url, {
      method: "GET",
    });
    return {
      data: withId(resource, response),
    };
  },

  getMany: async (resource, params) => {
    // Only works for users and posts
    // This just makes N requests
    let path = resourceToPath[resource];
    let response = await Promise.all(
      params.ids.map(async (id) => {
        const url = `${apiUrl}/${path}/${id}`;
        let response = await httpClient(url, {
          body: JSON.stringify(params.data),
          method: "GET",
        });
        return withId(resource, response);
      })
    );
    return {
      data: response,
    };
  },

  deleteMany: async (resource, params) => {
    let path = resourceToPath[resource];
    const url = `${apiUrl}/${path}`;
    let response = await httpClient(url, {
      body: JSON.stringify({ [deleteResourceKeys[resource]]: params.ids }),
      method: "DELETE",
    });
    return {
      data: response.map((obj) => withId(resource, obj)),
    };
  },

  create: async (resource, params) => {
    let path = resourceToPath[resource];
    const url = `${apiUrl}/${path}`;
    let response = await httpClient(url, {
      body: JSON.stringify(params.data),
      method: "POST",
    });
    return {
      data: withId(resource, response),
    };
  },

  update: async (resource, params) => {
    // Only works for users and posts
    let path = resourceToPath[resource];
    const url = `${apiUrl}/${path}/${params.id}`;
    let response = await httpClient(url, {
      body: JSON.stringify(params.data),
      method: "POST",
    });
    return {
      data: withId(resource, response),
    };
  },
};

export default dataProvider;
