import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";

export const ReportList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <ReferenceField source="post.postId" reference="posts">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="reportedBy.username" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="details" />
      <DateField source="createdAt" />
      <TextField source="id" />
    </Datagrid>
  </List>
);
