import * as React from "react";
import {
  Admin,
  AppBar,
  defaultTheme,
  Layout,
  Resource,
  ToggleThemeButton,
} from "react-admin";
import { authProvider } from "./auth";
import dataProvider from "./dataProvider";
import UserIcon from "@mui/icons-material/People";
import PostIcon from "@mui/icons-material/PersonPin";
import FeaturedIcon from "@mui/icons-material/Star";
import ReportIcon from "@mui/icons-material/ReportProblem";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AdminIcon from "@mui/icons-material/VpnLock";
import DeletedUsersIcon from "@mui/icons-material/DeleteForever";
import {
  DeletedUserList,
  UserCreate,
  UserEdit,
  UserList,
} from "./resources/User";
import { PostEdit, PostList } from "./resources/Post";
import { ReportList } from "./resources/Report";
import { FeedbackList } from "./resources/Feedback";
import { Box, createTheme, Typography } from "@mui/material";

const darkTheme = createTheme({
  palette: { mode: "dark" },
});

const MyAppBar = (props) => (
  <AppBar {...props}>
    <Box flex="1">
      <Typography variant="h6" id="react-admin-title"></Typography>
    </Box>
    <ToggleThemeButton lightTheme={defaultTheme} darkTheme={darkTheme} />
  </AppBar>
);

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} />;

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    layout={MyLayout}
  >
    <Resource
      name="users"
      icon={UserIcon}
      list={UserList}
      create={UserCreate}
      edit={UserEdit}
    />
    <Resource name="posts" icon={PostIcon} list={PostList} edit={PostEdit} />
    <Resource name="featured_users" icon={FeaturedIcon} list={UserList} />
    <Resource name="reports" icon={ReportIcon} list={ReportList} />
    <Resource
      name="feedback"
      icon={FeedbackIcon}
      options={{ label: "Feedback" }}
      list={FeedbackList}
    />
    <Resource name="admins" icon={AdminIcon} list={UserList} />
    <Resource
      name="deleted_users"
      icon={DeletedUsersIcon}
      list={DeletedUserList}
    />
  </Admin>
);

export default App;
