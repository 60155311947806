import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  FunctionField,
  ImageField,
  Labeled,
  List,
  ReferenceField,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";

const PostEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const PostList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="user.username" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="place.name" />
      <TextField source="category" />
      <TextField source="content" />
      <ImageField source="imageUrl" />
      <FunctionField
        source="stars"
        label="Stars"
        render={(post) =>
          post.stars === 0 ? "🫡" : post.stars && "⭐️".repeat(post.stars)
        }
      />
      <DateField source="createdAt" />
      <BooleanField source="deleted" />
    </Datagrid>
  </List>
);

export const PostEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<PostEditToolbar />}>
      <Labeled label="Post ID">
        <TextField source="id" />
      </Labeled>
      <Labeled label="Username">
        <TextField source="user.username" />
      </Labeled>
      <Labeled label="Place Name">
        <TextField source="place.name" />
      </Labeled>
      <Labeled label="Place ID">
        <TextField source="place.placeId" />
      </Labeled>
      <Labeled label="Category">
        <TextField source="category" />
      </Labeled>
      <Labeled label="Content">
        <TextInput source="content" />
      </Labeled>
      <Labeled label="Image">
        <ImageField source="imageUrl" />
      </Labeled>
      <Labeled label="Stars">
        <TextField source="stars" />
      </Labeled>
      <Labeled label="Created at">
        <DateField source="createdAt" />
      </Labeled>
      <BooleanInput source="deleted" />
    </SimpleForm>
  </Edit>
);
