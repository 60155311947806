import { useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Button,
  Confirm,
  Create,
  Datagrid,
  DateField,
  Edit,
  ImageField,
  List,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useNotify,
} from "react-admin";
import { apiUrl, httpClient } from "../dataProvider";

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const UserList = (props) => {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="userId" />
        <TextField source="uid" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phoneNumber" />
        <ImageField className="Row" source="profilePictureUrl" />
        <DateField source="createdAt" />
        <BooleanField source="isFeatured" />
        <BooleanField source="isAdmin" />
        <BooleanField source="deleted" />
      </Datagrid>
    </List>
  );
};

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="uid" />
      <TextInput source="username" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
    </SimpleForm>
  </Create>
);

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<UserEditToolbar />}>
      <TextField source="uid" />
      <TextInput source="username" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextField source="phoneNumber" />
      <ImageField source="profilePictureUrl" />
      <DateField source="createdAt" />
      <BooleanInput source="deleted" />
      <BooleanInput source="isFeatured" />
      <BooleanInput source="isAdmin" />
    </SimpleForm>
  </Edit>
);

const DeletedUserActions = ({ basePath, data, resource }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const notify = useNotify();

  return (
    <TopToolbar>
      <Button onClick={handleClick} label="Delete ALL" style={{ color: 'white', padding: '10px', backgroundColor: 'red' }} />
      <Confirm
        isOpen={open}
        isLoading={isLoading}
        title="Delete users"
        content="Are you sure you want to hard-delete all deactivated accounts (cannot undo)?"
        onConfirm={() => {
          setIsLoading(true);
          hardDeleteUsers(notify);
          setOpen(false);
        }}
        onClose={handleDialogClose}
      />
    </TopToolbar>
  )
};

function hardDeleteUsers(notify) {
  const url = `${apiUrl}/deleted-users`;
  const asyncFunc = async () => {
    try {
      let response = await httpClient(url, {
        method: "DELETE",
      });
      if (response.success) {
        notify("Deleted users", "success");
        return true;
      } else {
        notify("Failed to delete users", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      notify("Error while making request. Refresh the page and try again.", "warning");
      return false;
    }
  };
  asyncFunc().then(success => {
    if (success) {
      alert("Deleted users");
    } else {
      alert("Failed");
    }
  });
}

export const DeletedUserList = (props) => {
  return (
    <List actions={<DeletedUserActions />} {...props} >
      <Datagrid>
        <TextField source="id" />
        <TextField source="userId" />
        <TextField source="uid" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phoneNumber" />
        <ImageField className="Row" source="profilePictureUrl" />
        <DateField source="createdAt" />
        <BooleanField source="isFeatured" />
        <BooleanField source="isAdmin" />
        <BooleanField source="deleted" />
      </Datagrid>
    </List>
  );
};
