import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";

export const FeedbackList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="user.username" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="contents" />
      <BooleanField source="followUp" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);
